.Node {
    border: solid 3px red;
    border-radius: 1px;
    width: 100px;
    display: inline-block;
    font-size: 10px;
    margin: 5px;
    cursor: pointer;
}

.Node-name {
    font-weight: bold;
    font-size: larger; 
}

#orgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
    border-right: solid 3px blue;
}
  
#orgChart .nodeLineBorderTop {
    border-top: solid 3px red;
}
  
  
